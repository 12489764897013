<template>
  <div :class="{ developer_styles: isDevelopmentMode}">
  <CalculatorForm />
  </div>
</template>

<script>
import CalculatorForm from './components/CalculatorForm.vue'

export default {
  name: 'App',
  components: {
    CalculatorForm
  },
  data() {
    return {
      isDevelopmentMode: process.env.NODE_ENV === 'development'
    }
  },
}
</script>

<style lang="scss">
  @import "./assets/scss/variables.scss";
  @import "./assets/scss/element-plus-custom.scss";
  @import "./assets/scss/default.scss";

  .developer_styles {
    @import "./assets/scss/developer.scss";
  }
</style>
