export default class testForm {
  static getStepOneFormFields() {
    return process.env.NODE_ENV === 'development' ? this.getStepOnePrefillData() : this.getStepOneEmpty();
  }

  static getStepTwoFormFields() {
    return process.env.NODE_ENV === 'development' ? this.getStepTwoPrefillData() : this.getStepTwoEmpty();
  }

  // first
  static getStepOnePrefillData() {
    return {
      name: 'Маша',
      phone: '67646111',
      email: 'masha@gmail.com',
    }
  }

  static getStepOneEmpty() {
    return {
      name: '',
      phone: '',
      email: '',
    }
  }

  // second
  static getStepTwoPrefillData() {
    return {
      entities: [{
        place: 'apartment',
        square: '33',
        location: 'chisinau',
        types: [{"label" :"мыши/крысы","category":"rodents"}],
        cost: 0,
        discount: 0,
        isDiscount: false,
      }],
    }
  }

  static getStepTwoEmpty() {
    return {
      entities: [{
        place: 'apartment',
        square: '1',
        location: 'chisinau',
        types: [],
        cost: 0,
        discount: 0,
        isDiscount: false,
      }],
    }
  }
}
