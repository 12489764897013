<template>
  <div class="calculator-form">
    <!-- step 1 -->
    <transition name="el-fade-in-linear">
      <div v-show="showFirstStep">
        <h2 class="text-center">{{ acfData.trn_title }}</h2>
        <p class="calculator-form__intro text-center">{{ acfData.trn_sub_title }}</p>
        <el-form ref="stepOneForm" :model="stepOneForm" :rules="rules" :label-position="'top'">
        <el-row>
          <el-col :sm="8">
            <el-form-item
                :label="acfData.name_field_label"
                prop="name"
                :rules="[
                  {required: true, message: this.acfData.trn_required_field, trigger: ['blur', 'change']}
                ]">
              <el-input :placeholder="acfData.name_field_placeholder" v-model="stepOneForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="8">
            <el-form-item
                :label="acfData.tel_field_label"
                prop="phone"
                :rules="[
                  {required: true, message: this.acfData.trn_required_field, trigger: ['blur', 'change']},
                  {min: 8, message: this.acfData.trn_min_phone_numbers, trigger: ['blur', 'change']},
                  {max: 8, message: this.acfData.trn_max_phone_numbers, trigger: ['blur', 'change']}
                ]">
              <el-input v-model="stepOneForm.phone" :placeholder="78123456">
                <template slot="prepend">{{ acfData.tel_field_placeholder }}</template> <!-- eslint-disable-line -->
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="8">
            <el-form-item
                :label="acfData.email_field_label"
                prop="email"
                :rules="[
                    {type: 'email', message: this.acfData.trn_email_wrong_format, trigger: ['blur', 'change']}
                ]">
              <el-input :placeholder="acfData.email_field_placeholder" v-model="stepOneForm.email"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- Buttons -->
        <el-row class="text-center">
          <el-col :span="24">
            <el-form-item>
              <el-button type="success" @click="submitStepOneForm('stepOneForm')"  v-loading="loading" :disabled="loading">{{acfData.trn_continue_btn}}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      </div>
    </transition>

    <!-- step 2 -->
    <transition name="el-fade-in-linear">
      <div v-show="showSecondStep">
        <h2 class="text-center">{{ acfData.trn_title }}</h2>
        <p class="calculator-form__intro text-center">{{ acfData.trn_sub_title }}</p>
        <el-form ref="stepTwoForm" :model="stepTwoForm" :rules="rules" :label-position="'top'">
        <el-row class="card-object" v-for="(entity, index) in stepTwoForm.entities" :key="index">
          <el-col :sm="8">
            <el-form-item :label="acfData.place_field_label"
                          :rules="{ required: true, message: acfData.trn_required_field,  trigger: ['blur', 'change'] }"
                          :prop="'entities.' + index + '.place'">
              <el-select v-model="entity.place" @change="placeChanged()">
                <el-option
                    v-for="place in acfData.places"
                    :key="place.value"
                    :label="place.label"
                    :value="place.value">
                  {{ place.label }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="8">
            <el-form-item class="el-from-item-input__badge"
                          :label="acfData.square_field_label"
                          :rules="[{ required: true, message: acfData.trn_required_field,  trigger: ['blur', 'change'] }]"
                          :prop="'entities.' + index + '.square'">
              <el-input-number v-model="entity.square" clearable :min="1" :max="10000" :controls="false" @change="squareChanged()">
              </el-input-number>
              <span v-if="entity.place === 'plot'">{{acfData.trn_utin_acres}} </span>
              <span v-else>{{acfData.trn_utin_m}}</span>
            </el-form-item>
          </el-col>
          <el-col :sm="8">
            <el-form-item :label="acfData.location_field_label"
                          :rules="{ required: true, message: acfData.trn_required_field,  trigger: ['blur', 'change'] }"
                          :prop="'entities.' + index + '.location'">
              <el-select v-model="entity.location">
                <el-option
                    v-for="location in acfData.locations"
                    :key="location.value"
                    :label="location.label"
                    :value="location.value">
                  {{ location.label }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :sm="24">
            <el-form-item :label="acfData.processing_field_label"
                          :rules="{ required: true, message: acfData.trn_required_field,  trigger: ['blur', 'change'] }"
                          :prop="'entities.' + index + '.types'">
              <el-checkbox-group v-model="entity.types" @change="typeChanged()">
                <el-checkbox v-for="(type, index) in acfData.types_of_processing"
                             :label="type"
                             :key="index">
                  {{type.label}}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col class="entity-price">
            <p v-if="entity.cost">
              {{ acfData.trn_price }}
              <span v-bind:class="{ discount: entity.isDiscount }">{{entity.cost}}</span>
              &#8203;
              <span v-if="entity.isDiscount">{{entity.discount}}</span>
            </p>
          </el-col>
          <el-col :sm="8">
            <el-button :disabled="stepTwoForm.entities.length < 2" class="m-t-30" @click.prevent="removeEntity(entity)" type="danger"
                       icon="el-icon-delete">{{acfData.trn_btn_delete}}</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item>
              <el-button type="primary" @click="addEntity" icon="el-icon-plus">{{ acfData.trn_add_btn }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="total-price">
          <el-col>
            {{ acfData.trn_total_price }} {{total}} MDL
            <p><p class="calculator-form__descr">{{acfData.trn_discount}}</p>
          </el-col>
        </el-row>
        <!-- Buttons -->
        <el-row class="send-request">
          <el-col :span="24">
            <el-form-item>
              <el-button type="success" @click="submitStepTwoForm('stepTwoForm')" v-loading="loading" :disabled="loading">{{ acfData.trn_send_request }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      </div>
    </transition>

    <!-- success window -->
    <transition name="el-fade-in-linear">
      <div v-show="showSuccessWindow" class="success-window">
        <h2 class="success-window__title">{{acfData.trn_request_was_sent}}</h2>
        <p class="success-window__text">{{acfData.trn_thank_you_message}}</p>
        <img :src="`${this.publicPath}success.svg`" alt="success">
      </div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
import formInputs from "@/enum/formInputs";
import {calculatePrice, verifyDiscount} from "@/assets/js/calculator";

export default {
  name: 'CalculatorForm',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      loading: false,
      showFirstStep: true,
      showSecondStep: false,
      showSuccessWindow: false,
      stepOneForm: formInputs.getStepOneFormFields(),
      stepTwoForm: formInputs.getStepTwoFormFields(),
      acfData: [],
      pricing: [],
      rules: {},
      total: 0,
      leadId: '',
    }
  },
  methods: {
    submitStepOneForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true

          this.createLead().then(response => {
            if (response) {
               this.showFirstStep = false;
               this.showSecondStep = true;
               this.leadId = response.data;
             }

            this.loading = false;
          });
        } else {
          this.$notify.error({
            title: this.acfData.trn_error_notice_title,
            message: this.acfData.trn_error_form_text
          });

          return false;
        }
      });
    },
    submitStepTwoForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;

          this.updateLead().then(response => {
            if (response) {
              this.showSecondStep = false;
              this.showSuccessWindow = true;
            }

            this.loading = false;
          });
        } else {
          this.$notify.error({
            title: this.acfData.trn_error_notice_title,
            message: this.acfData.trn_error_form_text
          });

          return false;
        }
      });
    },
    squareChanged() {
      this.countPrice();
    },
    typeChanged() {
      this.countPrice();
    },
    placeChanged() {
      this.countPrice();
    },
    removeEntity(item) {
      var index = this.stepTwoForm.entities.indexOf(item);

      if (index !== -1) {
        this.stepTwoForm.entities.splice(index, 1);
      }

      this.countPrice();
    },
    addEntity() {
      this.stepTwoForm.entities.push({
        place: 'apartment',
        square: '1',
        location: 'chisinau',
        types: [],
        cost: 0,
        discount: 0,
        isDiscount: false,
      });
    },
    countPrice() {
      verifyDiscount(this.stepTwoForm.entities)
      calculatePrice(this.stepTwoForm.entities, this.pricing);

      let totalPrice = 0;

      this.stepTwoForm.entities.map(entity => {
        let entityCost = entity.isDiscount ? entity.discount : entity.cost;
        totalPrice = totalPrice + entityCost; // sum of prices
      });

      this.total = Math.round(totalPrice);
    },
    async createLead() {
      try {
        return await axios.post(`/wp-json/calculator-form/v1/add-lead`, this.stepOneForm);
      } catch (error) {
        this.$notify.error({
          title: this.acfData.trn_error_notice_title,
          message: this.acfData.trn_error_notice_text
        });
      }
    },
    async updateLead() {
      try {
        return await axios.put(`/wp-json/calculator-form/v1/update-lead`, {
          id: this.leadId,
          data: this.stepTwoForm,
          total: this.total,
        });
      } catch (error) {
        this.$notify.error({
          title: this.acfData.trn_error_notice_title,
          message: this.acfData.trn_error_notice_text
        });
      }
    },
    getDefaultData () {
      let lang = document.getElementsByTagName('html')[0].getAttribute('lang');

      if (lang === 'ro-RO') {
        lang = 'ro';
      } else if (lang === 'ru-Ru') {
        lang = 'ru'
      } else {
        lang = 'ru'
      }


      // TODO: test with build command, reduce paddings, deploy


      axios.get(`/wp-json/calculator-form/v1/custom-fields?lang=${lang}`)
          .then((response) => this.acfData = response.data)
          .catch(error => console.log(error));
    },
    getPriceData() {
      axios.get(`/wp-json/calculator-form/v1/get-pricing`)
          .then((response) => {
            this.pricing = JSON.parse(response.data)
            this.pricing = this.pricing[0];
          })
          .catch(error => console.log(error));
    }
  },
  beforeMount() {
    this.getDefaultData();
    this.getPriceData();
  }
}
</script>