export const calculatePrice = (entities, pricing) => {
  entities.map(entity => {
    if (areRequiredFieldsMissing(entity)) {
      entity.cost = 0;

      return;
    }

    let range = getNeededRange(entity.square, pricing.places[entity.place].ranges);
    let unitPriceVariation = getPriceVariation(entity.types);

    let area = range.area_for_calculation;
    let pricePerUnit = range.prices[unitPriceVariation];

    entity.cost = Math.round(area * pricePerUnit); // final price for one entity
    console.log(entity.cost);

    if (entity.isDiscount) {
      entity.discount = Math.round(entity.cost/2); // apply 50% discount
    }
  })
}

/**
 * Discount applies when there are plot and house in one order
 * Discount applies only for plot entity, house comes by full price
 *
 * @param entities
 * @returns {this is string[]}
 */
export const verifyDiscount = (entities) => {
  let discountCondition = ['house', 'plot'];
  let places = entities.map(entity => entity.place);

  let isDiscont = discountCondition.every(value => places.includes(value));

  entities.forEach(entity => {
    entity.isDiscount = false; // reset discount for all

    if (entity.place === 'plot') {
      entity.isDiscount = isDiscont; // apply only for plot
    }
  });
}

const areRequiredFieldsMissing = (entity) => {
  return typeof entity.square === 'undefined' || entity.types.length === 0;
}

/**
 * Get correct calculation value based on user square choice.
 * Example: Square is 39 m², belongs to range [0-50] and we take 50m² as value to calculate price
 *
 * @param square
 * @param ranges
 * @returns {{area_for_calculation: number}|unknown}
 */
const getNeededRange = (square, ranges) => {
  for (let [range, value] of Object.entries(ranges)) {
    let min = range.split('-')[0],
        max = range.split('-')[1];

    if ((min <= square) && (square <= max)) {
      return value;
    }
  }

  return ranges['no_limit']; // take last range, if no other
}

/**
 * Get price variation based on category total number variations (1, 2, 1+1, 3, 2+1 and so on)
 *
 * @param types
 * @returns {number} Price array key to get correct price array value
 */
const getPriceVariation = (types) =>  {
  let insectsTotal = 0,
      rodentsTotal = 0;

  types.map(type => {
    type.category === 'insects' ? insectsTotal++ : rodentsTotal++;
  });

  if (insectsTotal + rodentsTotal === 1) {
    // Один любой вид обратоки (насекомые или мыши) -> 1
    return 0;
  }

  if (insectsTotal === 2 && rodentsTotal === 0) {
    // Любые 2 вида обработки от насекомых(но не крысы/мыши) ->  2
    return 1;
  }

  if (insectsTotal === 1 && rodentsTotal === 1) {
    // Любые вид обработки от насекомых + крысы/мыши ->  1 + 1
    return 2;
  }

  if (insectsTotal === 3 && rodentsTotal === 0) {
    // Любые 3 вида обработки от насекомых (но не крысы/мыши) -> 3
    return 3;
  }

  if (insectsTotal === 2 && rodentsTotal === 1) {
    // Любые 2 вида обработки от насекомых + крысы/мыши ->  2 + 1'
    return 4;
  }

  if (insectsTotal === 4 && rodentsTotal === 0) {
    // Любые 4 вида обработки от насекомых(но не крысы/мыши) -> 4
    return 5;
  }

  if (insectsTotal === 3 && rodentsTotal === 1) {
    // Любые 3 вида обработки от насекомых + крысы/мыши -> 3 + 1
    return 6;
  }

  if (insectsTotal === 4 && rodentsTotal === 1) {
    // Любые 4 вида обработки от насекомых + крысы/мыши -> 3 + 1
    return 7;
  }
}